import { render, staticRenderFns } from "./Challenge5.vue?vue&type=template&id=64f1184c&scoped=true&lang=pug&"
import script from "./Challenge5.vue?vue&type=script&lang=ts&"
export * from "./Challenge5.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f1184c",
  null
  
)

export default component.exports