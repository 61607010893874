
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Contents3 extends Vue {
  private contents = [
    {
      question_image: ['/img/contents/WP16t1eihy0130300m01.png'],
      answer_image: '/img/contents/WP16t1eihy0130300t01.png',
      count: 4,
      results: [
        { collect: true, explanation: '/student/explanatory-video/understanding-1/5' },
        { collect: false },
        { collect: false },
        { collect: true, explanation: '/student/explanatory-video/understanding-1/5' },
      ],
    },
    {
      question_image: ['/img/contents/WP16t1eihy0330300d01.png', '/img/contents/WP16t1eihy0330300m01.png'],
      answer_image: '/img/contents/WP16t1eihy0330300t01.png',
      count: 5,
      results: [
        { collect: false },
        { collect: true, explanation: '/student/explanatory-video/understanding-1/5' },
        { collect: true, explanation: '/student/explanatory-video/understanding-1/5' },
        { collect: true, explanation: '/student/explanatory-video/understanding-1/5' },
        { collect: false },
      ],
    },
  ]
}
